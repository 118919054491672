<template>
  <div>
    <ExtendedView :tabs="tabs">
      <template v-slot:list="{ item }">
        <WidgetList
          v-if="item && item.key"
          :fixed-filters="fixedFilters(item.key)"
          :load-items-callback="getOrderStatusDisplays"
          :call-to-actions="callToActions"
          @selectWidget="selectDisplay"
        />
      </template>
      <template
        v-slot:profile
      >
        <WidgetProfile
          v-if="selectedOrderStatusDisplay"
          :widget="selectedOrderStatusDisplay"
          :update-call-back="showOrderStatusDisplayWithEdit"
          :change-active-status-callback="updateOrderStatusDisplay"
          :preview-url="'https://order-status.salescloud.is/?uuid=' + selectedOrderStatusDisplay.uuid"
          widget-type="order-status-display"
        />
      </template>
    </ExtendedView>
  </div>
</template>

<script>

import ExtendedView from '@/components/common/ExtendedView'
import WidgetProfile from "@/components/widget/WidgetProfile"
import WidgetList from "@/components/widget/WidgetList"

export default {
  name: 'OrderStatusDisplays',
  components: {
    WidgetList,
    ExtendedView,
    WidgetProfile
  },
  data() {
    return {
      widgets: [],
      selectedOrderStatusDisplay: null,
    }
  },
  methods: {
    fixedFilters(key) {
      switch (key) {
        case 'active':
          return [
            {
              key: 'active',
              type: 'boolean',
              value: true,
              operator: 'equals'
            }
          ]
        case 'inactive':
          return [
            {
              key: 'active',
              type: 'boolean',
              value: true,
              operator: 'not_equals'
            }
          ]
        default:
          return []
      }
    },
    addOrderStatusDisplay() {
      this.$store.commit('updateDataToMutate', {
        objectToMutate: {
          title: null,
          channels: [],
          location: null,
          posChannels: [],
          primaryColor: '#40C8E0',
          useDarkText: false
        },
        formComponent: 'components/orderStatusDisplays/OrderStatusDisplayForm',
        saveCallback: this.createOrderStatusDisplay,
        previewUrl: "https://order-status.salescloud.is/",
        previewStyle: 'padding-top: 177%;',
        title: this.$t('createWithPreview')
      })
    },
    createOrderStatusDisplay(newOSD) {
      this.$store.dispatch('createOrderStatusDisplay', newOSD).then(result => {
        this.$store.commit('updateActionSuccess', {
          message: this.$t('success'),
          subMessage: this.$t('successfullyCreatedOrderStatusDisplay')
        })
      }).catch(err => {
        this.$store.commit('updateActionError', {
          message: this.$t('error'),
          subMessage: this.$t('couldNotCreateOrderStatusDisplay') + '\n' + err
        })
      })
    },
    updateOrderStatusDisplay(newValues) {
      return this.$store.dispatch('updateOrderStatusDisplay', newValues).then(result => {
        this.$store.commit('updateActionSuccess', {
          message: this.$t('success'),
          subMessage: this.$t('successfullyUpdatedOrderStatusDisplay')
        })
      }).catch(err => {
        this.$store.commit('updateActionError', {
          message: this.$t('error'),
          subMessage: this.$t('couldNotUpdateOrderStatusDisplay') + '\n' + err
        })
      })
    },
    showOrderStatusDisplayWithEdit(profile) {
      return this.$store.commit('updateDataToMutate', {
        objectToMutate: profile,
        formComponent: 'components/orderStatusDisplays/OrderStatusDisplayForm',
        saveCallback: this.updateOrderStatusDisplay,
        previewUrl: 'https://order-status.salescloud.is',
        previewStyle: 'padding-top: 177%;',
        title: this.$t('editWithPreview')
      })
    },
    getOrderStatusDisplays() {
      return this.$store.dispatch('getOrderStatusDisplays').then(orderStatusDisplays => {
        this.widgets = orderStatusDisplays
        this.$store.commit('updateAppBarTabs', this.tabs)
        return orderStatusDisplays
      })
    },
    selectDisplay(display) {
      this.selectedOrderStatusDisplay = display
    },
  },
  computed: {
    selectedAppBarTab() {
      return this.$store.state.selectedAppBarTab
    },
    callToActions() {
      return [
        {
          title: this.$t('addOrderStatusDisplay'),
          callback: this.addOrderStatusDisplay
        }
      ]
    },
    activeCount() {
      if(Array.isArray(this.widgets)) {
        return this.widgets.filter(widget => widget && widget.active).length
      }
      return 0
    },
    inactiveCount() {
      if(Array.isArray(this.widgets)) {
        return this.widgets.filter(widget => widget && !widget.active).length
      }
      return 0
    },
    tabs() {
      const tabs = []

      tabs.push({
        title: 'Active',
        key: 'active',
        badge: {
          content: this.activeCount
        }
      })

      tabs.push({
        title: 'Inactive',
        key: 'inactive',
        badge: {
          content: this.inactiveCount
        }
      })

      return tabs
    },
  },
  watch: {
    widgets(value) {
      if(typeof this.selectedOrderStatusDisplay !== 'undefined' && this.selectedOrderStatusDisplay !== null) {
        const widgetsIndex = value.findIndex(w => w && w.uuid === this.selectedOrderStatusDisplay.uuid)
        if(widgetsIndex >= 0) {
          this.selectedOrderStatusDisplay = value[widgetsIndex]
        }
      }
    },
    selectedAppBarTab(current, previous) {
      if(current !== previous) {
        this.selectedOrderStatusDisplay = null
      }
    }
  },
  created() {
    this.getOrderStatusDisplays()

    this.$store.commit('updateAppBarTabs', this.tabs)
    if (Array.isArray(this.tabs) && this.tabs.length > 0) {
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
    }

    this.$store.commit('updateShowAppBar', false)
    this.$store.commit('updateShowSidebar', true)
  }
}
</script>
